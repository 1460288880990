import "./App.css";
import { lazy, Suspense } from 'react';
import { Routes, Route, Outlet } from "react-router-dom";
import usePageTracking from "./components/Helpers/PageTracking";
import Header from "./components/Header";
import Footer from "./components/Helpers/Footer";
import ScrollToTop from "./components/Helpers/ScrollToTop";

const HomePage = lazy(() => import('./components/HomePage.js'));
const PutCalculator = lazy(() => import('./components/Finance/PutCalculator'));
const CallCalculator = lazy(() => import('./components/Finance/CallCalculator'));
const NoMatch = lazy(() => import('./components/NoMatch'));
const OklchCalc = lazy(() => import('./components/Tech/OklchCalc'));
const TechIndexWithHeader = lazy(() => import('./components/Tech/TechIndexWithHeader'));
const FinanceIndexWithHeader = lazy(() => import('./components/Finance/FinanceIndexWithHeader'));
const SubnettingCalc = lazy(() => import('./components/Tech/SubnettingCalc'));
const ApyCalculator = lazy(() => import('./components/Finance/APYCalcualtor'));
const RunningPaceCalculator = lazy(() => import('./components/Health/RunningPaceCalcualtor'));

const HealthIndexWithHeader = lazy(() => import('./components/Health/HealthIndexWithHeader'));
const OtherIndex = lazy(() => import('./components/Other/OtherIndex'));
const NYCTipCalculator = lazy(() => import('./components/Finance/NYCTipCalculator'));
const ScientificCalculator = lazy(() => import('./components/Other/ScientificCalculator'));
const TDEECalculator = lazy(() => import('./components/Health/TDEEcalc'));
const HeightCalculator = lazy(() => import('./components/Health/HeightCalc'));
const PriceToWageCalculator = lazy(() => import('./components/Finance/PriceToWageCalculator'));
const NYCRentCalculator = lazy(() => import('./components/Finance/NYCRentAfforability'));
const HourlyWageCalculator = lazy(() => import('./components/Finance/HourlyWageCalculator'));
const SmoothieCalculator = lazy(() => import('./components/Health/SmoothieCalc'));
const TriathlonCalculator = lazy(() => import('./components/Health/TriathlonCalculator'));
const PizzaCalculator = lazy(() => import('./components/Finance/PizzaCostCalculator'));
const TimeDivisionCalculator = lazy(() => import('./components/Other/BPMCalculator'));
const SubnetCalculator = lazy(() => import('./components/Tech/VLSMCalculator'));
const NYCTaxCalculator = lazy(() => import('./components/Finance/NYCIncomeTax'));
const EarthquakeCalculator = lazy(() => import('./components/Other/EarthquakeCalculator'));
const PtoCalculator = lazy(() => import('./components/Finance/PTOCalculator'));
const HoursAgoCalculator = lazy(() => import('./components/Other/HoursAgo'));
const TimeAgoCalculator = lazy(() => import('./components/Other/TimeAgoCalculator'));
const LLMResourceCalculator = lazy(() => import('./components/Tech/LLMResourceCalculator'));

function App() {
  usePageTracking()

  return (
    <div className="App">
            <Header />
            <Suspense fallback={<div>Loading...</div>}>

      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
                  <ScrollToTop>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<PutCalculator />} />

          <Route path="finance" element={<Layout />} >
            <Route index element={<FinanceIndexWithHeader />} />
            <Route path="putapy" element={<PutCalculator />} />
            <Route path="callapy" element={<CallCalculator />} />
            <Route path="apy" element={<ApyCalculator />} />
            <Route path="nycholiday" element={<NYCTipCalculator />} />
            <Route path="nycrent" element={<NYCRentCalculator />} />
            <Route path="nycincometax" element={<NYCTaxCalculator />} />
            <Route path="pricetowage" element={<PriceToWageCalculator />} />
            <Route path="hourlywage" element={<HourlyWageCalculator />} />
            <Route path="pizzacost" element={<PizzaCalculator />} />
            <Route path="pto" element={<PtoCalculator />} />
            <Route path="*" element={<NoMatch />} />
          </Route>

          <Route path="tech" element={<Layout />}>
            <Route index element={<TechIndexWithHeader />} />
            <Route path="llmresources" element={<LLMResourceCalculator />} />
            <Route path="oklch" element={<OklchCalc />} />
            <Route path="vlsm" element={<SubnetCalculator />} />
            <Route path="subnet" element={<SubnettingCalc />} />
            <Route path="*" element={<NoMatch />} />
          </Route>

          <Route path="health" element={<Layout />}>
            <Route index element={<HealthIndexWithHeader />} />
            <Route path="runningpace" element={<RunningPaceCalculator />} />
            <Route path="triathlon" element={<TriathlonCalculator />} />
            <Route path="smoothie" element={<SmoothieCalculator />} />
            <Route path="tdee" element={<TDEECalculator />} />
            <Route path="height" element={<HeightCalculator />} />
            <Route path="*" element={<NoMatch />} />
          </Route>

          <Route path="other" element={<Layout />}>
            <Route index element={<OtherIndex />} />
            <Route path="scientificcalculator" element={<ScientificCalculator />} />
            <Route path="bpmtempo" element={<TimeDivisionCalculator />} />
            <Route path="earthquake" element={<EarthquakeCalculator />} />
            <Route path="timeago" element={<TimeAgoCalculator />} />
            <Route path="*" element={<NoMatch />} />
            <Route path="hoursago/:hours" element={<HoursAgoCalculator />} />
          </Route>

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
      </ScrollToTop>
      </Suspense>

      <Footer />
    </div>
  );
}

function Layout() {
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
                <Outlet />
    </div>
  );
}

export default App;
